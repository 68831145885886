<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,

      vehicle: null,

      payment: {
        modal: false,
      }
    };
  },
  methods: {
    getVehicle() {
      this.vehicle = null;

      api
        .get('vehicles/' + this.$route.params.id,)
        .then((response) => {
          if (response.data.status == 'success') {
            this.vehicle = response.data.vehicle

            if (this.vehicle && this.vehicle.id) {
              this.statData = [
                {
                  title: "Status",
                  value: this.vehicle.status,
                },
                {
                  title: "Valor",
                  value: this.$options.filters.currency(this.vehicle.plan)
                }
              ]

              if (this.vehicle.status == 'pending') {
                this.payment.status = 'pending'
                if (response.data.payment && response.data.payment.pix) {
                  this.payment.pix = response.data.payment.pix
                }

                this.showPayment();
              }
            }
          } else {
            this.$router.push('/vechicles')
          }
        })
    },
    showPayment() {
      this.payment.modal = false
      this.payment.modal = true
    }
  },
  mounted() {
    this.getVehicle()
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/vehicles">Meus Veículos</router-link></li>
      <li v-if="vehicle && vehicle.id" class="breadcrumb-item d-none d-sm-block">{{ vehicle.brand }} {{ vehicle.year }}</li>
    </ol>

    <div v-if="vehicle && vehicle.id" class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ vehicle.brand }} {{ vehicle.year }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!vehicle" class="text-center">
      <b-spinner variant="default" role="status"></b-spinner>
    </div>
    <div v-else-if="vehicle && !vehicle.id" class="card">
      <div class="card-body">
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M2.634 17.918a1.765 1.765 0 0 0 1.201 1.291l.18.791H4v2h16v-2H6.683a.84.84 0 0 0-.007-.278l-.196-.863 10.357-2.356.196.863a.886.886 0 0 0 1.06.667l.863-.197a.885.885 0 0 0 .667-1.06l-.251-1.103c.446-.416.67-1.046.525-1.683l-.59-2.59a1.76 1.76 0 0 0-1.262-1.307l-2.049-3.378a2.774 2.774 0 0 0-2.982-1.263l-7.868 1.79a2.769 2.769 0 0 0-2.144 2.43l-.387 3.932a1.76 1.76 0 0 0-.57 1.724l.589 2.59zm3.02-.688a1.327 1.327 0 1 1-.59-2.589 1.327 1.327 0 0 1 .59 2.589zm11.222-2.552a1.328 1.328 0 1 1-.59-2.587 1.328 1.328 0 0 1 .59 2.587zM5.589 9.192l7.869-1.791a.773.773 0 0 1 .83.351l1.585 2.613-.566.129-10.046 2.287-.568.129.299-3.042a.772.772 0 0 1 .597-.676zM18.405 4 17 2l-.5 3L19 9l3 1-2-2.539 2-.933-2-.933L22 2z">
            </path>
          </svg>
          <h5 class="mt-3">Você ainda não tem possui um veículo cadastrado.</h5>
          <p></p>
          <router-link tag="a" to="/vehicles" class="btn btn-default">Proteger agora</router-link>
        </div>
      </div>
    </div>
    <div v-else-if="vehicle && vehicle.id">
      <div class="row">
        <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-6 col-xl-3">
          <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
        </div>
      </div>

      <div class="d-flex flex-wrap" style="gap: 10px;">
        <div class="flex-fill">
          <div class="card m-0">
            <div class="card-body">
              <div><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Marca</span></div>
              <div class="mt-2 px-1">{{ vehicle.brand }}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="card m-0">
            <div class="card-body">
              <div><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Ano</span></div>
              <div class="mt-2 px-1">{{ vehicle.year }}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="card m-0">
            <div class="card-body">
              <div><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Modelo</span></div>
              <div class="mt-2 px-1">{{ vehicle.model }}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="card m-0">
            <div class="card-body">
              <div><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Cidade</span></div>
              <div class="mt-2 px-1">{{ vehicle.city }}/{{ vehicle.state }}</div>
            </div>
          </div>
        </div>
        <div class="flex-fill">
          <div class="card m-0">
            <div class="card-body">
              <div><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Táxi ou Aplicativo?</span></div>
              <div class="mt-2 px-1" v-if="vehicle.work == 'yes'">Sim</div>
              <div class="mt-2 px-1" v-else>Não</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="payment.modal" modal-class="modal-right" hide-header hide-footer>
      <div v-if="payment && payment.status && payment.status == 'pending'">
        <h4>Realize o pagamento da sua adesão</h4>
        <div class="my-5">
          <div v-if="vehicle.plan" class="card">
            <div class="card-body">
              <div class="mt-1"><span class="bg-soft-warning rounded py-1 px-2 font-size-12 text-uppercase">Valor da Adesão</span></div>
              <div class="mt-2 px-1">{{ vehicle.plan | currency }}</div>
            </div>
          </div>
        </div>
        <div v-if="payment.pix" class="text-center">
          <h5>Pagamento via PIX</h5>
          <div>
            Use o código QR para prosseguir com a transação.
          </div>
          <div class="my-4 d-flex justify-content-center">
            <div class="border rounded p-3">
              <img style="width: 180px" :src="payment.pix.image" />
            </div>
          </div>
          <div>
            <button class="btn btn-default btn-block" v-clipboard:copy="payment.pix.text"
              v-on:click="$toast.success('<strong>Copiado para a área de transferência.</strong><br>Agora, tudo o que você precisa fazer é acessar o aplicativo do seu banco e concluir o processo usando o <strong>Pix Copia e Cola</strong>.')">
              Pix Copia e Cola
            </button>
          </div>
        </div>
        <div v-else class="text-danger">
          Não foi possível gerar o pix para pagamento.
        </div>
      </div>

      <div class="mt-5">
        <a class="btn btn-outline-link" v-on:click="payment.modal = false">Fechar</a>
      </div>
    </b-modal>
  </Layout>
</template>